import { Pipe, PipeTransform } from '@angular/core';
import { API_PREFIX } from '../../../environments/environment';

@Pipe({
  name: 'imageDomainChange',
  standalone: true,
})
export class ImageDomainChangePipe implements PipeTransform {
  defaultImage = '/images/avatar_round.png';
  transform(value: string) {
    function isBase64(value: string) {
      const base64Pattern = /^data:image\/[a-zA-Z]+;base64,/;
      return base64Pattern.test(value);
    }
    if (value) {
      if (isBase64(value)) {
        return value;
      } else {
        value = API_PREFIX + '/img/' + value;
      }
    } else {
      value = this.defaultImage;
    }
    return value;
  }
}
